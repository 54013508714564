// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';

import StaticContentPage from '../components/common/static-content/static-content';

import { getCategorizedContent } from '../libs/static-content/static-content-utils';
import {useFAQQuery} from "../hooks/useFAQPageQuery";

const FAQsPage = () => {
  const {configPagesFaq: faqz} = useFAQQuery();
  const elements = getCategorizedContent(faqz.items);
  return (
    <Layout>
      <SEO title="Frequently Asked Questions" />
      <StaticContentPage
        title={faqz.label}
        subtitle={faqz.fa}
        items={elements}
        numItemsPerLine="3"
        viewAllLink=""
      />
    </Layout>
  );
};

export default FAQsPage;
